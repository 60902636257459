import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { setDeepValue } from "../util/util";
import Http from "../util/http";
import AccessControl from "../util/accessControl";
import VerifyClient from "./dashboard/verifyclient";
import Swal from 'sweetalert2';

export default function RetailClientInfoVerifyModal({
  open,
  setOpen,
  commUser,
  setCommUser,
  save,
}) {
  const cancelButtonRef = useRef(null);
  const [countries, setCountries] = useState([]);
  const [geo, setGeo] = useState({});
  useEffect(() => {
    Http.request("admin", "countries", []).then((response) => {
      setCountries(response);
      setGeo(JSON.parse(localStorage.geolocation));
    });
  }, []);

  function setKey(key, value) {
    let object = { ...commUser };

    setDeepValue(object, value, key);
    setCommUser(object);
  }

  function checkContact() {
    save(commUser);
    // if(commUser.emailVerified === false || commUser.smsVerified === false){
    //     setOpenVerify(true)
    // }else{
    //     save(commUser)
    // }
  }

    function handleSave() {
        const requiredFields = ['firstname', 'lastname', 'contactEmail', 'addressFirstLine', 'addressCityOrTown', 'addressStateOrProvince', 'addressCountry', 'dateOfBirth', 'placeOfBirth', 'nationality', 'idType', 'id', 'issuedDate', 'expiryDate'];
        let isValid = true;
        requiredFields.forEach(field => {
            if (!commUser[field]) {
                isValid = false;
                // Optionally, add logic to display error messages
                console.error(`Validation failed: ${field} is required.`);
            }
        });
    
        if (isValid) {
            save(commUser);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill out all required fields!',
            })
        }
    }

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                      <PencilSquareIcon
                        className="h-6 w-6 text-white-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Verify Retail Client Information
                      </Dialog.Title>
                      <div className="mt-10">
                        <div className="p-7">
                          <div>
                            <h2 className="text-2xl">Names</h2>
                          </div>

                          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey("firstname", e.target.value);
                                }}
                                value={commUser.firstname}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                First Name*
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey("middlename", e.target.value);
                                }}
                                value={commUser.middlename}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Middle Name*
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey("lastname", e.target.value);
                                }}
                                value={commUser.lastname}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Last Name*
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="p-7">
                          <div>
                            <h2 className="text-2xl">Contact Info</h2>
                          </div>

                          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey("contactEmail", e.target.value);
                                }}
                                value={commUser.contactEmail}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Email Address*
                              </label>
                            </div>
                          </div>
                          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 mt-5">
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey("contactMobilePhone", e.target.value);
                                }}
                                value={commUser.contactMobilePhone}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Phone Number (M)*
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                type="text"
                                onChange={(e) => {
                                  setKey("contactHousePhone", e.target.value);
                                }}
                                value={commUser.contactHousePhone}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Phone Number (H)
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                type="text"
                                onChange={(e) => {
                                  setKey("contactWorkPhone", e.target.value);
                                }}
                                value={commUser.contactWorkPhone}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Phone Number (W)
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="p-7">
                          <div>
                            <h2 className="text-2xl">Address</h2>
                          </div>

                          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey("addressFirstLine", e.target.value);
                                }}
                                value={commUser.addressFirstLine}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Address - First Line*
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                type="text"
                                required
                                onChange={(e) => {
                                  setKey("addressSecondLine", e.target.value);
                                }}
                                value={commUser.addressSecondLine}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Address - Second Line*
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey("addressCityOrTown", e.target.value);
                                }}
                                value={commUser.addressCityOrTown}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Address - City or Town*
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey(
                                    "addressStateOrProvince",
                                    e.target.value
                                  );
                                }}
                                value={commUser.addressStateOrProvince}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Address - State or Province*
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <select
                                required
                                name="country"
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) => {
                                  setKey("addressCountry", e.target.value);
                                }}
                                value={commUser.addressCountry}
                              >
                                <option key="">Select Country</option>
                                {countries.map((item) => (
                                  <option key={item.code} value={item.code}>
                                    {item.title}
                                  </option>
                                ))}
                              </select>

                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Address - Country*
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="p-7">
                          <div>
                            <h2 className="text-2xl">Personal Details</h2>
                          </div>

                          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                type="date"
                                required
                                rows="10"
                                onChange={(e) => {
                                  setKey("dateOfBirth", e.target.value);
                                }}
                                value={commUser.dateOfBirth}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                                Date of Birth*
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey("placeOfBirth", e.target.value);
                                }}
                                value={commUser.placeOfBirth}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Place of Birth*
                              </label>
                            </div>

                            <div className="col-span-1 sm:col-span-1">
                              <select
                                required
                                name="country"
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(e) => {
                                  setKey("nationality", e.target.value);
                                }}
                                value={commUser.nationality}
                              >
                                <option key="">Select Country</option>
                                {countries.map((item) => (
                                  <option key={item.code} value={item.code}>
                                    {item.title}
                                  </option>
                                ))}
                              </select>

                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Nationality*
                              </label>
                            </div>
                          </div>

                          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                            <div className="col-span-1 sm:col-span-1">
                              <select
                                required
                                rows="10"
                                onChange={(e) => {
                                  setKey("idType", e.target.value);
                                }}
                                value={commUser.idType}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value=""> Select ID Type</option>
                                <option value="National ID">National ID</option>
                                <option value="Driver's License">
                                  Driver's Licenses
                                </option>
                                <option value="Driver's Licence">
                                  Driver's Licenses
                                </option>
                                <option value="Passport">Passport</option>
                              </select>
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                ID Type*
                              </label>
                            </div>
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="text"
                                onChange={(e) => {
                                  setKey("id", e.target.value);
                                }}
                                value={commUser.id}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                ID Number*
                              </label>
                            </div>

                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="date"
                                onChange={(e) => {
                                  setKey("issuedDate", e.target.value);
                                }}
                                value={commUser.issuedDate}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                ID Issued Date
                              </label>
                            </div>
                          </div>

                          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                            <div className="col-span-1 sm:col-span-1">
                              <input
                                required
                                type="date"
                                onChange={(e) => {
                                  setKey("expiryDate", e.target.value);
                                }}
                                value={commUser.expiryDate}
                                className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                ID Expiry Date
                              </label>
                            </div>
                          </div>
                          <br />

                          {geo.code === "lca" && (
                            <div className="col-span-4 sm:col-span-4">
                              <div className="flex items-center">
                                <label className="block text-sm font-medium leading-6 text-gray-900 mr-2">
                                  Politically Exposed Person
                                </label>
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setKey(
                                      "politicallyExposed",
                                      e.target.checked
                                    );
                                  }}
                                  checked={commUser.politicallyExposed}
                                  className=" block rounded-md px-3 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {geo.code === "lca" && (
                          <div className="p-7">
                            <div>
                              <h2 className="text-2xl">Employment</h2>
                            </div>

                            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                              <div className="col-span-1 sm:col-span-1">
                                <input
                                  type="text"
                                  required
                                  onChange={(e) => {
                                    setKey(
                                      "employmentOccupation",
                                      e.target.value
                                    );
                                  }}
                                  value={commUser.employmentOccupation}
                                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <label className="block text-sm font-medium leading-6 text-gray-900 mb-3">
                                  Occupation*
                                </label>
                              </div>
                              <div className="col-span-1 sm:col-span-1">
                                <input
                                  required
                                  type="text"
                                  onChange={(e) => {
                                    setKey(
                                      "employmentEmployer",
                                      e.target.value
                                    );
                                  }}
                                  value={commUser.employmentEmployer}
                                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Employer*
                                </label>
                              </div>

                              <div className="col-span-1 sm:col-span-1">
                                <input
                                  required
                                  type="text"
                                  onChange={(e) => {
                                    setKey(
                                      "employmentPosition",
                                      e.target.value
                                    );
                                  }}
                                  value={commUser.employmentPosition}
                                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Position*
                                </label>
                              </div>
                            </div>

                            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3">
                              <div className="col-span-1 sm:col-span-1">
                                <input
                                  type="text"
                                  required
                                  onChange={(e) => {
                                    setKey(
                                      "employmentSourceOfWealth",
                                      e.target.value
                                    );
                                  }}
                                  value={commUser.employmentSourceOfWealth}
                                  className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Source of Wealth*
                                </label>
                              </div>{" "}
                              <br />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <AccessControl
                      permission={"verifyRetailClientInfo"}
                      fallback={<></>}
                      target={["god"]}
                    >
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => {
                          checkContact();
                        }}
                      >
                        Continue
                      </button>
                    </AccessControl>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
