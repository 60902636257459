import logo from "./logo.svg";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Index from "./pages/index";
import SignIn from "./pages/signin";
import Reset from "./pages/reset";
import Activate from "./pages/activate";

import DashboardLayout from "./layouts/dashboard";
import DashboardIndex from "./pages/dashboard/index";
import ClientsIndex from "./pages/dashboard/clients/index";
import CreateRetailClient from "./pages/dashboard/clients/retail/create";
import RetailProfile from "./pages/dashboard/clients/retail/profile/[pid]";
import RetailClientNested from "./pages/dashboard/clients/retail/profile/[pid]/[con]";
import ContractsIndex from "./pages/dashboard/contracts/index";
import Cancellations from "./pages/dashboard/contracts/cancellations";
import CancellationRoom from "./pages/dashboard/cancellations/cancel-room";
import CreateRetailContract from "./pages/dashboard/contracts/retail/create";
import CreateCommercialContract from "./pages/dashboard/contracts/commercial/create";
import AnnouncementEdit from "./pages/dashboard/announcements/[pid]";
import AnnouncementsIndex from "./pages/dashboard/announcements/";
import AnnouncementCreate from "./pages/dashboard/announcements/create";
import CommercialClientCreate from "./pages/dashboard/clients/commercial/create";
import CommercialClientProfile from "./pages/dashboard/clients/commercial/profile/[pid]";
import CommercialClientProfileNested from "./pages/dashboard/clients/commercial/profile/[pid]/[con]";
import OrganizationInfo from "./pages/dashboard/organizations/[pid]";
import OrganizationCreate from "./pages/dashboard/organizations/create";
import OrganizationsIndex from "./pages/dashboard/organizations/index";
import PermissionEdit from "./pages/dashboard/permissions/[pid]";
import PermissionCreate from "./pages/dashboard/permissions/create";
import PermissionsIndex from "./pages/dashboard/permissions/index";
import RolesEdit from "./pages/dashboard/roles/[pid]";
import RoleCreate from "./pages/dashboard/roles/create";
import RolesIndex from "./pages/dashboard/roles/index";
import UserEdit from "./pages/dashboard/users/[pid]";
import UserCreate from "./pages/dashboard/users/create";
import UsersIndex from "./pages/dashboard/users/index";
import Profile from "./pages/dashboard/profile";
import IPEdit from "./pages/dashboard/ips/[pid]";
import IPCreate from "./pages/dashboard/ips/create";
import IPSIndex from "./pages/dashboard/ips/index";
import AuditEdit from "./pages/dashboard/audits/[pid]";
import AuditsIndex from "./pages/dashboard/audits/index";
import NotFound from "./pages/dashboard/clients/not-found";
import Error401 from "./pages/error/401";
import Error403 from "./pages/error/403";
import Error404 from "./pages/error/404";
import ArrearsPage from "./pages/dashboard/contracts/arrears";
import Refunds from "./pages/dashboard/contracts/refunds";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Index />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/activate" element={<Activate />} />

        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<DashboardIndex />} />
          <Route path="profile" element={<Profile />} />

          <Route path="clients" element={<ClientsIndex />} />
          <Route
            path="clients/retail/create"
            element={<CreateRetailClient />}
          />
          <Route
            path="clients/commercial/create"
            element={<CommercialClientCreate />}
          />
          <Route
            path="clients/retail/profile/:id"
            element={<RetailProfile />}
          />
          <Route
            path="clients/commercial/profile/:id"
            element={<CommercialClientProfile />}
          />
          <Route
            path="clients/retail/profile/:id/:con"
            element={<RetailClientNested />}
          />
          <Route
            path="clients/commercial/profile/:id/:con"
            element={<CommercialClientProfileNested />}
          />
          <Route path="clients/not-found" element={<NotFound />} />

          <Route path="contracts" element={<ContractsIndex />} />
          <Route path="contracts/arrears" element={<ArrearsPage />} />
          <Route path={"contracts/refunds"} element={<Refunds />} />
          <Route path="contracts/cancellations" element={<Cancellations />} />
          <Route
            path="cancellations/cancel-room"
            element={<CancellationRoom />}
          />
          <Route
            path="contracts/retail/create"
            element={<CreateRetailContract />}
          />
          <Route
            path="contracts/commercial/create"
            element={<CreateCommercialContract />}
          />

          <Route path="announcements/:id" element={<AnnouncementEdit />} />
          <Route path="announcements" element={<AnnouncementsIndex />} />
          <Route path="announcements/create" element={<AnnouncementCreate />} />

          <Route path="organizations/:id" element={<OrganizationInfo />} />
          <Route path="organizations/create" element={<OrganizationCreate />} />
          <Route path="organizations" element={<OrganizationsIndex />} />

          <Route path="permissions/:id" element={<PermissionEdit />} />
          <Route path="permissions/create" element={<PermissionCreate />} />
          <Route path="permissions" element={<PermissionsIndex />} />

          <Route path="roles/:id" element={<RolesEdit />} />
          <Route path="roles/create" element={<RoleCreate />} />
          <Route path="roles" element={<RolesIndex />} />

          <Route path="users/:id/" element={<UserEdit />} />
          <Route path="users/create" element={<UserCreate />} />
          <Route path="users" element={<UsersIndex />} />

          <Route path="ips/:id" element={<IPEdit />} />
          <Route path="ips/create" element={<IPCreate />} />
          <Route path="ips" element={<IPSIndex />} />

          <Route path="audits/:id" element={<AuditEdit />} />
          <Route path="audits" element={<AuditsIndex />} />
        </Route>
        <Route path="error/401" element={<Error404 />} />
        <Route path="error/403" element={<Error403 />} />
        <Route path="error/404" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
