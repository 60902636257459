import {useEffect, useState} from 'react'
import {setDeepValue} from '../../../util/util'
import Http from '../../../util/http'
import Swal from 'sweetalert2'
import AccessControl from '../../../util/accessControl'
import WEditor from "../../../components/WYSIWYGEditor"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function AnnouncementCreate() {
    const [organizations, setOrganizations] = useState([])
    const [announcement, setAnnouncement] = useState({
        name: '',
        title: '',
        content: '',
        organization: '',
        type: 'warning',
        isActive: true,
        startDate:'',
        endDate:''
    })
    const [userInfo, setUserInfo] = useState({
        userId:'',
        userName:''
    })
    const [showEditor, setShowEditor] = useState(false)

    useEffect(() => {
        getOrganizations().then(() => {
            const userInfo = localStorage.getItem('user');
            const user = JSON.parse(userInfo);
            setUserInfo({
                userId: user._id,
                userName: `${user.firstName} ${user.lastName}`
            });
            setShowEditor(true);
        })
    }, [])

    function setKey(key, value) {
        let object = {...announcement}
        setDeepValue(object, value, key)
        setAnnouncement(object)
    }

    function setContent(data){
        setKey('content',data);
    }

    async function getOrganizations() {
        Http.request('admin', 'organizations/get-all').then((response) => {
            setOrganizations(response.organizations)
        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to make request to servers.',
            })
        })
    }

    function save(e) {
        let dialog = Swal.fire({
            title: 'Loading...',
            timerProgressBar: true,
            allowOutsideClick: false, 
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
            }
          })
        e.preventDefault();
        try {
            Http.request('admin', 'notifications/create', {announcement,userInfo}).then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Hurray!',
                    text: 'Announcement added successfully.',
                }).then(() => {
                    dialog.close()
                    window.location = `${window.location.origin}/dashboard/announcements`
                })
            }).catch((err) => {
                dialog.close()
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err?.message || "Server error",
                })
            })
        } catch (err) {
             dialog.close()
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to make request to servers.',
            })
        }
    }

    return (
        <div className="shadow sm:overflow-hidden sm:rounded-md ">
            <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
                <form onSubmit={(e) => {
                    save(e)
                }}>
                    <div className="shadow sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                            <div>
                                <h2 className="text-base font-semibold leading-6 text-gray-900">Announcement
                                    Information</h2>
                                <p className="mt-1 text-sm text-gray-500">Please ensure all fields are filled in
                                    correctly.</p>
                            </div>

                            <div className="grid grid-cols-6 gap-10">
                                <div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            setKey('name', e.target.value)
                                        }}
                                        value={announcement.name}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Description
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            setKey('title', e.target.value)
                                        }}
                                        value={announcement.title}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                               {/* <div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Start Date
                                    </label>
                                    <input
                                        type="date"
                                        onChange={(e) => {
                                            setKey('startDate', e.target.value)
                                        }}
                                        value={announcement.startDate}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        End Date
                                    </label>
                                    <input
                                        type="date"
                                        onChange={(e) => {
                                            setKey('endDate', e.target.value)
                                        }}
                                        value={announcement.endDate}
                                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>*/}

                                <div className="col-span-6 sm:col-span-6">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Content
                                    </label>
                                    { showEditor && (
                                        <WEditor id={undefined} setContent={setContent}></WEditor>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Organization
                                    </label>
                                    <select
                                        id="type"
                                        name="type"
                                        autoComplete="type"
                                        value={announcement.organization}
                                        onChange={(e) => {
                                            setKey('organization', e.target.value)
                                        }}
                                        className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option value=""> Select an Organization </option>
                                        <option value="ALL"> All Organizations </option>
                                        {organizations.map((organization, index) => {
                                            return (
                                                <option key={index}
                                                        value={organization._id}>{organization.name}</option>
                                            )
                                        })

                                        }

                                    </select>
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Type
                                    </label>
                                    <select
                                        id="type"
                                        name="type"
                                        autoComplete="type"
                                        value={announcement.type}
                                        onChange={(e) => {
                                            setKey('type', e.target.value)
                                        }}
                                        className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        <option key="0" value="info"> Info</option>
                                        <option key="1" value="warning"> Warning</option>
                                        <option key="2" value="success"> Success</option>
                                        <option key="3" value="critical"> Critical</option>
                                    </select>
                                </div>

                                <div className="col-span-6 sm:col-span-2">
                                    <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Is Active
                                    </label>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            setKey('isActive', e.target.checked)
                                        }}
                                        checked={announcement.isActive}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
                            <a href='/dashboard/announcements' type="button"
                               class="text-sm font-semibold leading-6 text-gray-900 mx-3">Cancel</a>
                            <AccessControl fallback={<></>} permission={'createAnnouncement'} target={['god']}>
                                <button
                                    type="submit"
                                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save
                                </button>
                            </AccessControl>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}