import React from "react";
import moment from "moment";
import Http from "../../../../util/http";
import Swal from "sweetalert2";
import { findFlagUrlByIso3Code } from "country-flags-svg";

import { useEffect, useState } from "react";
import ConsumerClientInfoVerifyModal from "../../../../components/ConsumerClientInfoVerifyModal";
import http from "../../../../util/http";
import Infopib from "../../../../components/infopib";
import VerifyClientModal from "../../../../components/dashboard/verifyclient";

export default function CreateRetailContract() {
  const [client, setClient] = useState({
    personal: {},
    contact: {},
    address: {},
  });
  const [info, setInfo] = useState({
    premium: 0,
    months: 0,
    startDate: "",
    paymentDate: "",
    policyType: "",
    paymentType: "cash",
    insuranceCompany: "",
    policyNumber: "",
    policyEndDate: "",
  });
  const [summary, setSummary] = useState({
    data: [],
    headers: {},
  });
  const [modalToggle, setModalToggle] = useState(false);
  const [coverTypes, setCoverTypes] = useState([]);
  const [modalAction, setModalAction] = useState(0);
  const [commUser, setCommUser] = useState({
    _id: "",
    firstname: "",
    middlename: "",
    lastname: "",
    contactEmail: "",
    contactMobilePhone: "",
    contactHousePhone: "",
    contactWorkPhone: "",
    addressFirstLine: "",
    addressSecondLine: "",
    addressCityAndTown: "",
    addressStateOrProvince: "",
    addressCountry: "",
    dateOfBirth: "",
    placeOfBirth: "",
    nationality: "",
    idType: "",
    id: "",
    issuedDate: "",
    expiryDate: "",
    politicallyExposed: false,
    employmentOccupation: "",
    employmentEmployer: "",
    employmentPosition: "",
    employmentSourceOfWealth: "",
  });
  const [organization, setOrganization] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [openVerify, setOpenVerify] = useState(false);

  useEffect(() => {
    async function Init() {
      setOrganization(JSON.parse(localStorage.organization));
      const contractDraft = window.localStorage.getItem("contractDraft");
      if (typeof contractDraft !== "undefined" && contractDraft !== null) {
        const contractDraftContent = JSON.parse(contractDraft);
        if (contractDraftContent.action !== "retail") return;

        setInfo(contractDraftContent.payload.info);
        try {
          contractDraftContent.payload.info.months = parseInt(
            contractDraftContent.payload.info.months || 0
          );
          contractDraftContent.payload.info.premium = parseFloat(
            contractDraftContent.payload.info.premium || 0
          );
          contractDraftContent.payload.info.type = "retail";
          window.localStorage.removeItem("contractDraft");
          let response = await Http.request(
            "contracts",
            "contracts/amortise",
            contractDraftContent.payload.info
          );
          setSummary(response);
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.message,
          });
        }
      } else {
        window.localStorage.removeItem("contractDraft");
      }
    }

    async function fetchAPI() {
      let dialog = Swal.fire({
        title: "Loading...",
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {},
      });
      try {
        let response = await Http.request(
          "contracts",
          "clients/retail/get-one",
          { id: window.location.search.replace("?id=", "") }
        );
        console.log({ response });
        setClient(response);
        await getCoverTypes();
        await getCompanies();
        dialog.close();
      } catch (e) {
        dialog.close();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to make request to servers.",
        }).then(() => {
          window.location.href = `${window.location.origin}/dashboard/clients/not-found`;
        });
      }
    }

    fetchAPI().then(() => {
      Init();
    });
  }, []);
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  async function changeVal(val, key) {
    let payload = { ...info };
    payload[key] = val;
    if (key === "startDate") {
      let _date = moment(val).add(1, "months").format().substring(0, 10);
      payload.paymentDate = _date;
      console.log({ payload });
    }
    setInfo(payload);
    await delay(1000);
    let isBetween = inRange(payload);
    if (isBetween) {
      await getSummary(payload);
    }
  }

  async function createContract() {
    let dialog = Swal.fire({
      title: "Loading...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {},
    });
    try {
      if (summary.data.length === 0) {
        return;
      } else {
        let payload = { ...info };
        payload.summary = summary;
        payload.client = client;
        payload.user = localStorage.getItem("user")._id;
        let response = await Http.request(
          "contracts",
          "contracts/retail/create",
          payload
        );
        setModalToggle(false);
        dialog.close();
        Swal.fire({
          icon: "success",
          title: "Hurray!",
          text: `Client retail contract created successfully. Contract will be returned to your inbox shortly`,
        }).then(() => {
          window.location.href = `${
            window.location.origin
          }/dashboard/clients/retail/profile/${window.location.search.replace(
            "?id=",
            ""
          )}`;
        });
      }
    } catch (err) {
      dialog.close();
      setModalToggle(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to create a contract for this client. Please contact Solis staff.",
      });
    }
  }

  function saveModalData(obj) {
    //let isvalidPolicyEnd = isContractPeriodValid();
    let isvalidPolicyEnd = true;
    if (!isvalidPolicyEnd) return;
    const personal = {
      firstname: obj.firstname,
      middlename: obj.middlename,
      lastname: obj.lastname,
      dateOfBirth: obj.dateOfBirth,
      placeOfBirth: obj.placeOfBirth,
      nationality: obj.nationality,
      idType: obj.idType,
      id: obj.id,
      issuedDate: obj.issuedDate,
      expiryDate: obj.expiryDate,
      politicallyExposed: obj.politicallyExposed,
    };

    const address = {
      addressFirstLine: obj.addressFirstLine,
      addressSecondLine: obj.addressSecondLine,
      addressCityOrTown: obj.addressCityOrTown,
      addressStateOrProvince: obj.addressStateOrProvince,
      addressCountry: obj.addressCountry,
    };
    const employment = {
      occupation: obj.employmentOccupation,
      employer: obj.employmentEmployer,
      position: obj.employmentPosition,
      sourceOfWealth: obj.employmentSourceOfWealth,
    };
    const contact = {
      email: obj.contactEmail,
      mobilePhone: obj.contactMobilePhone,
      housePhone: obj.contactHousePhone,
      workPhone: obj.contactWorkPhone,
    };
    let retail = {
      personal,
      address,
      employment,
      contact,
    };
    let expired = isIdExpired(personal);
    if (!expired) {
      http
        .request(`contracts`, "clients/retail/update-one", {
          id: obj._id,
          retail,
        })
        .then((result) => {
          Swal.fire({
            icon: "success",
            title: "Hurray!",
            text: `Retail client information saved`,
          })
            .then(() => {
              createContract();
            })
            .catch((e) => {
              createContract();
            });
        });
    } else {
      //setModalToggle(false);
    }
  }

  async function setDay(day) {
    let paymentDate;
    let daysInMonth = moment(info.startDate).add(1, "months").daysInMonth();
    if (day === 30 && daysInMonth < 30) {
      day = daysInMonth;
    }
    paymentDate = moment(info.startDate)
      .set({ date: day })
      .add(1, "month")
      .format()
      .substring(0, 10);
    if (
      info.paymentDate !== "" &&
      info.paymentDate !== undefined &&
      info.paymentDate !== null
    ) {
      paymentDate = moment(new Date(`${info.startDate}`))
        .add(1, "month")
        .set({ date: day })
        .format()
        .substring(0, 10);
    }

    let payload = { ...info };
    payload.paymentDate = paymentDate;
    setInfo(payload);
    getSummary(payload);
  }

  async function getSummary(payload) {
    let ids = ["premium", "months", "policyStart", "paymentDate"];
    setInvalidFields(ids);
    console.log({payload})
    if (parseInt(payload.months) > 11 || parseInt(payload.months) < 3) {
      console.log("Contract period must be between 3 and 12 months");
      return
    }
    let isBetween = inRange(payload);
    if (!isBetween) {
      return;
    }
    try {
      if (payload.months < 2) {
        return;
      }
      if (
        payload.premium === 0 ||
        payload.months === undefined ||
        payload.months === 0 ||
        payload.months === "" ||
        payload.startDate === "" ||
        payload.paymentDate === ""
      ) {
        return;
      }
      let validsecond = isvalidSecondPaymentDate(
        payload.startDate,
        payload.paymentDate
      );
      if (!validsecond) {
        return;
      }
      payload.months = parseInt(payload.months || 0);
      payload.premium = parseFloat(payload.premium || 0);
      let response = await Http.request(
        "contracts",
        "contracts/amortise",
        payload
      );
      setSummary(response);
    } catch (err) {
      console.log(err);
    }
  }

  async function setMonth(val) {
    console.log({ val });
    if (val === "") {
      await changeVal("", "months");
      return;
    }

    if (parseInt(val) < 12 || parseInt(val) > 2) {
      await changeVal(val, "months");
      //getSummary()
    }
  }

  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }

  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function openModal() {
    let ids = [
      "premium",
      "paymentType",
      "insuranceCompany",
      "policyNumber",
      "policyType",
      "months",
      "policyStart",
      "policyEndDate",
      "paymentDate",
    ];
    setInvalidFields(ids);
    let _info = { ...info };
    _info.policyType = document.getElementById("policyType").value;
    _info.paymentType = document.getElementById("paymentType").value;
    _info.insuranceCompany = document.getElementById("insuranceCompany").value;
    if (parseInt(info.months) > 11 || parseInt(info.months) < 3) {
      alert("Contract period must be between 3 and 12 months");
      return
    }
    setInfo(_info);
    let isBetween = inRange(_info);
    if (!isBetween) {
      Swal.fire({
        icon: "info",
        title: "Opps!",
        // state that start date cannot be older than today
        text: "Please ensure that the start date is not older than today.",
      });
      return;
    }
    if (
      _info.policyEndDate !== undefined &&
      _info.policyEndDate.length > 0 &&
      _info.policyType !== undefined &&
      _info.policyType.length > 0 &&
      _info.paymentType !== undefined &&
      _info.paymentType.length > 0 &&
      _info.insuranceCompany !== undefined &&
      _info.insuranceCompany.length > 0 &&
      _info.policyNumber !== undefined &&
      _info.policyNumber.length > 0 &&
      _info.premium !== undefined &&
      _info.premium > 0 &&
      _info.months !== undefined &&
      _info.months > 1 &&
      _info.startDate !== undefined &&
      _info.startDate.length > 1 &&
      _info.paymentDate !== undefined &&
      _info.paymentDate.length > 1
    ) {
      setCommUser({
        _id: client._id,
        firstname: client.personal.firstname,
        middlename: client.personal.middlename,
        lastname: client.personal.lastname,
        contactEmail: client.contact.email,
        contactMobilePhone: client.contact.mobilePhone,
        contactHousePhone: client.contact.housePhone,
        contactWorkPhone: client.contact.workPhone,
        addressFirstLine: client.address.addressFirstLine,
        addressSecondLine: client.address.addressSecondLine,
        addressCityAndTown: client.address.addressCityAndTown,
        addressStateOrProvince: client.address.addressStateOrProvince,
        addressCountry: client.address.addressCountry,
        dateOfBirth: client.personal.dateOfBirth,
        placeOfBirth: client.personal.placeOfBirth,
        nationality: client.personal.nationality,
        idType: client.personal.idType,
        id: client.personal.id,
        issuedDate: client.personal.issuedDate,
        expiryDate: client.personal.expiryDate,
        politicallyExposed: client.personal.politicallyExposed,
        employmentOccupation: client.employment.occupation,
        employmentEmployer: client.employment.employer,
        employmentPosition: client.employment.position,
        employmentSourceOfWealth: client.employment.sourceOfWealth,
        smsVerified: client.smsVerified,
        emailVerified: client.emailVerified,
        type: client.type,
      });
      setModalToggle(true);
    } else {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Please ensure that all fields are filled out.",
      });
    }
    console.log({ info });
  }

  async function getCoverTypes() {
    try {
      let payload = {
        text: "",
        sort: "",
        total: 0,
        page: 1,
        count: 0,
        perPage: 999,
        type: "",
        companyType: "",
      };
      let response = await Http.request("admin", "coverTypes/get-all", payload);
      if (coverTypes.length < 1) {
        response.coverages = response.coverages.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.name === value.name)
        );
        let _info = { ...info };
        _info.paymentType = "cash";
        // _info.policyType = response.coverages[0].name;
        _info.policyType = '';
        _info.insuranceCompany = JSON.parse(localStorage.organization)._id;
        setInfo(_info);
        setCoverTypes(response.coverages);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to make request to servers.",
      });
    }
  }

  async function getCompanies() {
    try {
      let payload = {
        page: 1,
        sort: "",
        total: 2,
        count: 2,
        perPage: 100,
        companyType: "Insurance Company",
        limit: 100,
      };
      let response = await Http.request(
        "admin",
        "organizations/get-all",
        payload
      );

      let _org = JSON.parse(localStorage.organization);
      if (
        _org.type === "Insurance Company" ||
        _org.type === "BFTTL Administration"
      ) {
        setCompanies([_org]);
      } else {
        setCompanies(response.organizations);
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to get companies.",
      });
    }
  }

  async function setInvalidFields(ids) {
    for (let i = 0; i < ids.length; i++) {
      let input = document.getElementById(ids[i]);

      if (input.value === "0" || input.value === "") {
        input.className =
          "block w-9/12 rounded-md border-0 py-1.5 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6";
      } else {
        input.className =
          "block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";
      }
    }
  }

  function inRange(payload) {
    let isBetween = false;
    try {
      let date = moment(payload.startDate);
      isBetween = date.isBetween(
        moment().subtract(1, "day"),
        moment().add(2, "years")
      );
      console.log({ isBetween, date: date.toDate(), raw: payload.startDate });
    } catch (error) {
      console.log("Error checking date", error);
    }
    return isBetween;
  }

  function isvalidSecondPaymentDate(startDate, secondPaymentDate) {
    var start = moment(startDate);
    var second = moment(secondPaymentDate);
    console.log({ start, second });
    var diff = second.diff(start, "day");
    console.log("diff", diff);
    if (diff > 0 && diff <= 61) {
      console.log("in range");
      return true;
    } else {
      console.log("out of range");
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Payment Date can only be within a 2 month range of Start Date.",
      });
      return false;
    }
  }

  function isIdExpired(personal) {
    console.log({ personal });
    if (personal.expiryDate === undefined || personal.expiryDate.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Please ensure that client id information filled out.",
      });
      return true;
    }
    let _expiryDate = moment(personal.expiryDate);
    let isExpired = _expiryDate.isBefore(
      moment(info.startDate).add(1, "weeks")
    );
    console.log({ isExpired, _expiryDate: _expiryDate.toDate(), info: info });
    if (isExpired) {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Please use another form of client identification. This one has expired or is expiring soon.",
      });
      return true;
    } else {
      return false;
    }
  }

  function isContractPeriodValid() {
    var ldate = new Date(
      new Date(
        summary.data[summary.data.length - 1]?.dueDate.replaceAll("-", "/")
      ).setHours(0, 0, 0, 0)
    );
    var ppdate = new Date(
      new Date(info.policyEndDate.replaceAll("-", "/")).setHours(0, 0, 0, 0)
    );
    var sdate = new Date(
      new Date(info.startDate.replaceAll("-", "/")).setHours(0, 0, 0, 0)
    );
    var diffDays = parseInt((ldate - ppdate) / (1000 * 60 * 60 * 24), 10);
    var isAfterStart = ppdate > sdate;
    let isValid = false;
    console.log({
      ldate,
      ppdate,
      isValid,
      diffDays,
      isAfterStart,
    });
    if (diffDays >= 30 && isAfterStart === true) {
      isValid = true;
    }
    if (!isValid) {
      Swal.fire({
        icon: "info",
        title:
          "Please review the policy end date it does not meet the requirements.",
        text: "Last payment for the contract should be at least one month prior to the policy end date.",
      });
      return isValid;
    } else {
      return true;
    }
  }

  function startVerify() {
    setOpenVerify(true);
  }

  function nextModal() {
    openModal();
  }

  return (
    <>
      <Infopib />
      <VerifyClientModal
        target={"mobile"}
        show={openVerify}
        setShow={setOpenVerify}
        client={client}
        openModal={openModal}
      />
      <div className="mx-20">
        <div className="mt-6 flex justify-center w-100">
          <div className="w-100">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              New IPF Contract
            </h2>
            <ConsumerClientInfoVerifyModal
              open={modalToggle}
              setOpen={setModalToggle}
              commUser={commUser}
              setCommUser={setCommUser}
              save={saveModalData}
            ></ConsumerClientInfoVerifyModal>
          </div>
        </div>
        <div className="mt-7 flex">
          <div
            className="w-1/12"
            style={{
              border: "1px solid black",
              borderRadius: "12%",
              height: "64px",
              width: "64px",
            }}
          >
            <img style={{}} src="/avatar4.png" alt="Solis Credit" />
          </div>
          <div className="w-11/12 grid ml-4" style={{}}>
            <div className="min-w-100">
              <h2 className="text-xl  leading-7 text-gray-900">
                {nullTransform(client.personal.firstname)}{" "}
                {nullTransform(client.personal.lastname)}
              </h2>
            </div>
            <div className="min-w-100 flex mt-3">
              <div className="mr-2 mt-1" style={{ width: "35px" }}>
                <img
                  className="mb-2"
                  style={{ width: "35px", height: "19px" }}
                  src={findFlagUrlByIso3Code(
                    nullTransform(client.address.addressCountry)
                  )}
                />
              </div>
              <div className="text-sm font-semibold leading-7 text-gray-900">
                {nullTransform(client.clientNumber || "")}
              </div>
              <div className="flex">
                <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                  Email: 
                </div>
                <div className="ml-2 text-sm leading-7 text-gray-900">
                  {nullTransform(client.contact.email)}
                </div>
              </div>
              <div className="flex">
                <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                  {nullTransform(client.personal.idType || "")}:
                </div>
                <div className="ml-2 text-sm leading-7 text-gray-900">
                  {nullTransform(client.personal.id || "")}
                </div>
              </div>
              <div className="flex">
                <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                  Phone: 
                </div>
                <div className="ml-2 text-sm leading-7 text-gray-900">
                  {nullTransform(client.contact.mobilePhone)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-7" />

        <div className="flex w-100 pt-12">
          <div className="grid w-1/2 mr-10">
            <div>
              <div className="text-xl font-bold leading-7 text-gray-900">
                Insurance Premium:
              </div>
              <div className="text-sm text-gray-500">
                Total premium to be financed,including tax.
              </div>
              <div className="mt-2">
                <input
                  onChange={(e) => {
                    changeVal(parseFloat(e.target.value), "premium");
                  }}
                  value={info.premium}
                  type="number"
                  name="premium"
                  id="premium"
                  className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter premium"
                />
              </div>

              <>
                <div>
                  <div className="text-xl font-bold leading-7 text-gray-900 mt-6">
                    Payment Type:
                  </div>
                  <div className="text-sm text-gray-500">
                    How will you be paying.
                  </div>
                  <input
                      required
                      onChange={(e) => {
                        changeVal(e.target.value, "paymentType");
                      }}
                      id={"paymentType"}
                      value={info.paymentType}
                      disabled
                      className="mt-2 block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                  </input>
                </div>
              </>
            </div>


            <div className="text-xl font-bold leading-7 text-gray-900 mt-5">
              Insurance Company
            </div>
            <div className="text-sm text-gray-500">
              Enter desired insurance company.
            </div>
            <div className="mt-2">
              <select
                  id="insuranceCompany"
                  required
                  onChange={(e) => {
                    changeVal(e.target.value, "insuranceCompany");
                  }}
                  value={info.insuranceCompany}
                  className="mt-2 block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select Insurance Company</option>
                {companies.map((company) => (
                  <option value={company._id}>{company.name}</option>
                ))}
              </select>
            </div>


            <div className="text-xl font-bold leading-7 text-gray-900 mt-5">
              Policy #:
            </div>
            <div className="text-sm text-gray-500">
              Enter Policy number here.
            </div>
            <div className="mt-2">
              <input
                onChange={(e) => {
                  changeVal(e.target.value, "policyNumber");
                }}
                value={info.policyNumber}
                type="text"
                name="policyNumber"
                id="policyNumber"
                className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter policy #"
              />
            </div>

            <div>
              <div className="text-xl font-bold leading-7 text-gray-900 mt-6">
                Policy Type:
              </div>

              <select
                id={"policyType"}
                required
                onChange={(e) => {
                  changeVal(e.target.value, "policyType");
                }}
                value={info.policyType}
                className="mt-2 block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select Policy Type</option>
                {coverTypes.map((policy) => (
                  <option value={policy.name}>{policy.name}</option>
                ))}
              </select>
            </div>
            <div className="mt-6">
              <div className="text-xl font-bold leading-7 text-gray-900">
                Contract Period:
              </div>
              <div className="text-sm text-gray-500">
                Number of months for payback.
              </div>
              <div className="mt-2 flex">
                <input
                  value={info.months}
                  onChange={(e) => {
                    setMonth(e.target.value);
                  }}
                  type="number"
                  name="months"
                  id="months"
                  className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter months"
                />
                <div className="flex w-8/12 ml-3">
                  <span
                    onClick={() => {
                      changeVal("3", "months");
                    }}
                    style={{ width: "50px", cursor: "pointer" }}
                    className="flex justify-center rounded-full bg-gray-300 px-2 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                  >
                    3
                  </span>
                  <span
                    onClick={() => {
                      changeVal("6", "months");
                    }}
                    style={{ width: "50px", cursor: "pointer" }}
                    className="flex justify-center rounded-full bg-gray-300 px-2 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                  >
                    6
                  </span>
                  <span
                    onClick={() => {
                      changeVal("11", "months");
                    }}
                    style={{ width: "50px", cursor: "pointer" }}
                    className="flex justify-center rounded-full bg-gray-300 px-2 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                  >
                    11
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-6">
            <div className="text-xl font-bold leading-7 text-gray-900">
                First Payment Date:
              </div>
              <div className="text-sm text-gray-500">
                Please input date that your policy starts here.
              </div>
              <div className="mt-2 flex">
  <input
    value={info.startDate}
    onChange={(e) => {
      changeVal(e.target.value, "startDate");
    }}
    type="date"
    name="start"
    id="policyStart"
    className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    min={new Date().toISOString().split('T')[0]} // Sets the minimum selectable date to today
  />
  <div className="flex w-8/12 ml-3">
    <span
      onClick={() => {
        changeVal(
          new Date().toISOString().substring(0, 10), // Ensures the button sets today's date
          "startDate"
        );
      }}
      style={{ cursor: "pointer" }}
      className="flex justify-center rounded-full bg-gray-300 px-3 py-1 text-sm font-medium text-gray-600 pt-2"
    >
      Today
    </span>
  </div>
</div>

            </div>

            <div className="mt-6">
              <div className="text-xl font-bold leading-7 text-gray-900">
                Policy End Date:
              </div>
              <div className="text-sm text-gray-500">
                Please input date that your policy ends here.
              </div>

              <div className="mt-2 flex">
                <input
                    value={info.policyEndDate}
                    onChange={(e) => {
                      changeVal(e.target.value, "policyEndDate");
                    }}
                    type="date"
                    name="policyEndDate"
                    id="policyEndDate"
                    className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    min={new Date().toISOString().split('T')[0]} // Sets the minimum selectable date to today
                />
              </div>
            </div>

            <div className="mt-6">
              <div className="text-xl font-bold leading-7 text-gray-900">
              Preferred Payment Date:
              </div>
              <div className="text-sm text-gray-500">
                What day of the month will regular payments be made?
              </div>
              <div className="mt-2 flex">
                <input
                  value={info.paymentDate}
                  onChange={(e) => {
                    changeVal(e.target.value, "paymentDate");
                  }}
                  min={new Date().toISOString().split('T')[0]} // Sets the minimum selectable date to today
                  type="date"
                  name="start"
                  id="paymentDate"
                  className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="flex w-8/12 ml-3">
                  <span
                    onClick={() => {
                      setDay(15);
                    }}
                    style={{ cursor: "pointer" }}
                    className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2"
                  >
                    15
                  </span>
                  <span
                    onClick={() => {
                      setDay(25);
                    }}
                    style={{ cursor: "pointer" }}
                    className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                  >
                    25
                  </span>
                  <span
                    onClick={() => {
                      setDay(30);
                    }}
                    style={{ cursor: "pointer" }}
                    className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                  >
                    30
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center mt-6">
              <label className="block text-xl font-medium leading-6 text-gray-900 mr-2  ml-3">
                Is it a mortgage?
              </label>
              <input
                type="checkbox"
                onChange={(e) => {
                  changeVal(e.target.checked, "mortgage");
                }}
                checked={info.mortgage}
                className=" block rounded-md px-3 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="grid w-1/2">
            <div>
              <div className="text-2xl leading-7 text-gray-900">Summary</div>
              <div className="grid pt-5">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Interest Rate:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {summary.headers.interest || 0}%
                </div>
              </div>
              <div className="grid pt-4">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Interest Amount:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(summary.headers.totalInterest || 0).toFixed(2)
                    )
                  )}
                </div>
              </div>
              <div className="grid pt-4">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Premium Financed + Interest:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  $
                  {FormatNumber(
                    nullTransform(
                      parseFloat(summary.headers.total || 0).toFixed(2)
                    )
                  )}
                </div>
              </div>
              <div className="grid pt-4 mb-4">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  First Installment Amount:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {summary.data.length > 0 && (
                    <>
                      $
                      {FormatNumber(
                        nullTransform(
                          parseFloat(summary.data[0].instalment).toFixed(2) ||
                            ""
                        )
                      )}
                    </>
                  )}
                  {summary.data.length === 0 && <>$0</>}
                </div>
              </div>
              <div className="grid pt-4 mb-4">
                <div className="text-lg leading-6 text-gray-900 w-100">
                  Monthly Installment Amount:
                </div>
                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                  {summary.data.length > 0 && (
                    <>
                      $
                      {FormatNumber(
                        nullTransform(
                          parseFloat(summary.data[1]?.instalment).toFixed(2) ||
                            ""
                        )
                      )}{" "}
                      starting{" "}
                      {
                        moment(nullTransform(summary.data[0].dueDate || ""))
                          .format("ll")
                          .split(",")[0]
                      }
                    </>
                  )}
                  {summary.data.length === 0 && <>$0 starting</>}
                </div>
              </div>
              <div className="grid mt- pt-6 w-1/3">
                <button
                  onClick={() => {
                    openModal();
                  }}
                  type="button"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create Contract
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-7" />
        <div className="flex w-100 pt-5">
          <div className="grid w-100">
            <div className="text-2xl leading-7 text-gray-900">
              Payment Schedule
            </div>
            {summary.data.length > 0 && (
              <div style={{ minWidth: "750px" }} className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Payment Date
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Principal
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Interest
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Total Instalment
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Balance
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {summary.data[0]?.dueDate !== "Invalid date" && (
                            <>
                              {summary.data.map((record, index) => (
                                <tr key={index}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {record.dueDate}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    $
                                    {FormatNumber(
                                      parseFloat(record.principal).toFixed(2)
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    $
                                    {FormatNumber(
                                      parseFloat(record.interest).toFixed(2)
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    $
                                    {FormatNumber(
                                      parseFloat(record.instalment).toFixed(2)
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    $
                                    {FormatNumber(
                                      parseFloat(record.balance).toFixed(2)
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}