/* eslint-disable import/first */
import React from "react";
import { useEffect, useState } from "react";
import Flag from "react-world-flags";
import { findFlagUrlByIso3Code } from "country-flags-svg";

var countryList = require("country-list");
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert2";
import CommercialClientAmortiseModal from "../CommercialClientAmortiseModal";
import VerifyClientModal from "./verifyclient";

export default function ClientListItem(props) {
  console.log({ props });
  const [modalToggle, setModalToggle] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [modalLink, setModalLink] = useState("");
  const [name, setName] = useState("");

  function openModal(action, link, status) {
    let curOrganization = JSON.parse(localStorage.getItem("geolocation"));

    if (status !== "active") {
      swal
        .fire({
          title: "Client is disabled",
          text: "Please contact Bayshore",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonText: "Cancel",
          cancelButtonColor: "#d33",
          confirmButtonText: "Go to Profile Page",
        })
        .then((result) => {
          if (result.isConfirmed) {
            window.location.href = `/dashboard/clients/${props.client.type}/profile/${props.client._id}`;
          } else {
          }
        });
    } else {
      setModalAction(action);
      setModalLink(link);
      setModalToggle(true);
    }
  }

  function saveModalData(action, payload) {
    window.localStorage.setItem(
      "contractDraft",
      JSON.stringify({ action, payload })
    );
    window.location.href = modalLink;
  }

  useEffect(() => {
    if (props.client.type === "retail") {
      setName(
        `${props.client.personal.firstname} ${props.client.personal.lastname}`
      );
    } else {
      setName(props.client.commercial.businessName);
    }
  }, [props]);

  return (
    <div
      className={
        "flex  p-3 mt-2" +
        (props.index % 2 === 0 ? " bg-gray-200" : " bg-gray-100")
      }
    >
      <CommercialClientAmortiseModal
        open={modalToggle}
        setOpen={setModalToggle}
        action={modalAction}
        save={saveModalData}
        link={`/dashboard/clients/${props.client.type}/profile/${props.client._id}`}
        name={name}
        client={props.client}
      ></CommercialClientAmortiseModal>
      <div
        className="w-1/12"
        style={{
          border: "1px solid black",
          borderRadius: "12%",
          height: "64px",
          width: "64px",
        }}
      >
        <img style={{}} src="/avatar4.png" alt="Solis Credit" />
      </div>
      <div
        className="w-9/12 grid ml-4"
        onClick={(e) => {
          openModal(
            props.client.type,
            `/dashboard/contracts/${props.client.type}/create?id=${props.client._id}`,
            props.client.status
          );
        }}
      >
        <div className={"flex"}>
          <div className={"w-1/2"}>
            <a
              href="#"
              className="text-xl font-semibold leading-7 text-gray-900"
            >
              {props.client.type === "retail" && (
                <>
                  {props.client.personal.firstname}{" "}
                  {props.client.personal.middlename}{" "}
                  {props.client.personal.lastname}
                </>
              )}
              {props.client.type === "commercial" && (
                <>{props.client.commercial.businessName}</>
              )}
            </a>
          </div>

          {/* <div className={"w-1/2"}>
            <div className="flex">
              <p className="col-span-1 mr-2">Verified:</p>
              <>
                <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-300">
                  <svg
                    className={
                      "h-1.5 w-1.5 " +
                      (props.client?.emailVerified === true
                        ? "fill-green-500"
                        : "fill-red-500")
                    }
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                  >
                    <circle cx={3} cy={3} r={3} />
                  </svg>
                  Email
                </span>
                <span className="mx-2 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-300">
                  <svg
                    className={
                      "h-1.5 w-1.5 " +
                      (props.client?.smsVerified === true
                        ? "fill-green-500"
                        : "fill-red-500")
                    }
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                  >
                    <circle cx={3} cy={3} r={3} />
                  </svg>
                  Telephone
                </span>
              </>
            </div>
          </div> */}
        </div>

        <div className="min-w-100 flex mt-3">
          <div className="mr-2 mt-1" style={{ width: "35px" }}>
            {props.client.type === "retail" && (
              <img
                className="mb-2"
                style={{ width: "35px", height: "19px" }}
                src={findFlagUrlByIso3Code(props.client.address.addressCountry)}
              />
            )}
            {props.client.type === "commercial" && (
              <img
                className="mb-2"
                style={{ width: "35px", height: "19px" }}
                src={findFlagUrlByIso3Code(props.client.address.addressCountry)}
              />
            )}
          </div>
          <div className="text-sm font-semibold leading-7 text-gray-900">
            {props.client.clientNumber || ""}
          </div>
          <div className="ml-3 text-sm  leading-7 text-gray-900">
            {props.client.contact.email}
          </div>
          {props.client.type === "retail" && (
            <div className="flex">
              <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                {props.client.personal.idType}:
              </div>
              <div className="ml-2 text-sm leading-7 text-gray-900">
                {props.client.personal.id}
              </div>
            </div>
          )}
          {props.client.type === "commercial" && (
            <div className="flex">
              <div className="ml-3 text-sm font-semibold leading-7 text-gray-900">
                Registration: 
              </div>
              <div className="ml-2 text-sm leading-7 text-gray-900">
                {props.client.commercial.registrationNumber}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-1/10 gird justify-end">
        {props.client.status === "active" && (
          <div className="text-5xl text-center mt-3">
            <FontAwesomeIcon
              icon={faCheck}
              className="fas fa-check"
              style={{ color: "green", marginLeft: "43px" }}
            />
          </div>
        )}
        {props.client.status !== "active" && (
          <>
            <div className="text-2xl text-center mt-3">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="fas fa-triangle-exclamation text-center"
                style={{ color: "red" }}
              />
            </div>
            <div style={{ color: "red" }}>Contact SOLIS</div>
          </>
        )}
      </div>
    </div>
  );
}
