import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { setDeepValue, Capitalize } from "../util/util";
import Http from "../util/http";
import AccessControl from "../util/accessControl";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import VerifyClient from "./dashboard/verifyclient";

export default function CommercialClientAmortiseModal({
  open,
  setOpen,
  action,
  save,
  link,
  name,
  client,
}) {
  const cancelButtonRef = useRef(null);
  const [info, setInfo] = useState({
    name: "",
    premium: 0,
    months: "",
    startDate: "",
    paymentDate: "",
  });
  const [policies, setPolicies] = useState([
    {
      number: 0,
      type: "",
      premium: 0,
      company: "",
    },
  ]);
  const [summary, setSummary] = useState({
    data: [],
    headers: {
      interest: 2,
    },
  });
  const [title, setTitle] = useState("");
  const [premiumMask, setPremiumMask] = useState("$0.00");
  const [openVerify, setOpenVerify] = useState(false);
  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  async function changeVal(val, key) {
    let payload = { ...info };
    payload[key] = val;

    if (key === "startDate") {
      let _date = moment(val).add(1, "months").format().substring(0, 10);
      payload.paymentDate = _date;
      console.log({ payload });
    }
    setInfo(payload);
    await delay(1000);
    if (key === "premium") {
      if (isNaN(val) || val <= 0) {
        setPremiumMask("$0.00");
      } else {
        const CurrencyFormat = Intl.NumberFormat("en-us", {
          style: "currency",
          currency: "USD",
        });
        setPremiumMask(CurrencyFormat.format(val));
      }
    }

    await getSummary(payload);
  }

  async function setDay(day) {
    let paymentDate;
    let daysInMonth = moment(info.startDate).add(1, "months").daysInMonth();
    if (day === 30 && daysInMonth < 30) {
      day = daysInMonth;
    }
    paymentDate = moment(info.startDate)
      .set({ date: day })
      .add(1, "month")
      .format()
      .substring(0, 10);
    if (
      info.paymentDate !== "" &&
      info.paymentDate !== undefined &&
      info.paymentDate !== null
    ) {
      paymentDate = moment(new Date(`${info.startDate}`))
        .add(1, "month")
        .set({ date: day })
        .format()
        .substring(0, 10);
    }

    let payload = { ...info };
    payload.paymentDate = paymentDate;
    setInfo(payload);
    getSummary(payload);
  }

  function isvalidSecondPaymentDate(startDate, secondPaymentDate) {
    var start = moment(startDate);
    var second = moment(secondPaymentDate);
    console.log({ start, second });
    var diff = second.diff(start, "day");
    console.log("diff", diff);
    if (diff > 0 && diff <= 61) {
      console.log("in range");
      return true;
    } else {
      console.log("out of range");
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "Payment Date can only be within a 2 month range of Start Date.",
      });
      return false;
    }
  }

  function checkContact() {
    if (client.emailVerified !== true || client.smsVerified !== true) {
      let text =
        "This clients email and telephone has not been verified. Would you like to do so now!\nPress OK to continue.";
      if (window.confirm(text) === true) {
        window.location.href = link;
      } else {
        setOpen(false);
      }
    } else {
    }
  }

  async function getSummary(payload) {
    try {
      if(payload.months < 3 || payload.months > 12){
        console.log("Months must be between 2 and 12");
        return
      }
      if (
        payload.premium === 0 ||
        payload.months === "" ||
        payload.startDate === "" ||
        payload.paymentDate === ""
      ) {
        return;
      }
      let validsecond = isvalidSecondPaymentDate(
        payload.startDate,
        payload.paymentDate
      );
      if (!validsecond) {
        return;
      }
      let isBetween = inRange(payload);
      if (!isBetween) {
        return;
      }

      if (payload.premium > 0 && link?.includes("commercial")) {
        let newSummary = { ...summary };
        newSummary.headers.interest = await getInterest(payload.premium);
        setSummary(newSummary);
      }
      payload.months = parseInt(payload.months || 0);
      payload.premium = parseFloat(payload.premium || 0);
      payload.type = action;
      let response = await Http.request(
        "contracts",
        "contracts/amortise",
        payload
      );
      setSummary(response);
    } catch (err) {
      console.log(err);
    }
  }

  async function getInterest(premium) {
    try {
      let response = await Http.request("contracts", "contracts/get-interest", {
        premium,
      });
      console.log({ response });
      return response.rate;
    } catch (err) {
      console.log(err);
    }
  }

  async function setMonth(val) {
    console.log({ val });
    if (val === "") {
      await changeVal("", "months");
      return;
    }

    if (parseInt(val) < 12) {
      await changeVal(val, "months");
      //getSummary()
    }
  }

  function nullTransform(val) {
    if (val === null || val === undefined) {
      return "";
    }
    return val;
  }

  function FormatNumber(str) {
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function inRange(payload) {
    let isBetween = false;
    try {
      let date = moment(payload.startDate);
      isBetween = date.isBetween(
        moment().subtract(1, "day"),
        moment().add(2, "years")
      );
      console.log({ isBetween, date: date.toDate(), raw: payload.startDate });
    } catch (error) {
      console.log("Error checking date", error);
    }
    return isBetween;
  }

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all">
                  {action === "commercial" && (
                    <div>
                      <div className="flex divide-x">
                        <div className="w-10/12 font-bold text-xl">
                          New {Capitalize(action)} Summary for {name}
                        </div>
                        <div className="w-2/12 mx-0">
                          {link !== undefined && (
                          <a
                            href={link}
                            type="button"
                            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            View Profile
                            <i className="fa-regular fa-user"></i>
                          </a>
                        )}
                          {link === undefined && (
                            <button
                              onClick={() =>
                                save(action, { info, policies, summary })
                              }
                              type="button"
                              className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Create Profile
                              <i className="fa-regular fa-user"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="">
                        <div className="flex w-100 pt-10">
                          <div className="grid w-1/2 mr-10">
                            <div className="mb-6">
                              <div className="text-xl font-bold leading-7 text-gray-900">
                                Insurance Premium:
                              </div>
                              <div className="text-sm text-gray-500">
                                Total premium to be financed,including tax.
                              </div>
                              <div className="mt-2">
                                <input
                                  onChange={(e) => {
                                    changeVal(
                                      parseFloat(e.target.value),
                                      "premium"
                                    );
                                  }}
                                  step="0.01"
                                  type="number"
                                  name="premium"
                                  id="premium"
                                  className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Enter premium"
                                />
                                <p>Premium Value: {premiumMask}</p>
                              </div>
                            </div>
                            <div className="">
                              <div className="text-xl font-bold leading-7 text-gray-900">
                                Contract Period:
                              </div>
                              <div className="text-sm text-gray-500">
                                Number of months for payback.
                              </div>
                              <div className="mt-2 flex">
                                <input
                                  value={info.months}
                                  onChange={(e) => {
                                    setMonth(e.target.value);
                                  }}
                                  type="number"
                                  name="months"
                                  id="months"
                                  className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  placeholder="Enter months"
                                />
                                <div className="flex w-8/12 ml-3">
                                  <span
                                    onClick={() => {
                                      changeVal("3", "months");
                                    }}
                                    style={{ width: "50px", cursor: "pointer" }}
                                    className="flex justify-center rounded-full bg-gray-300 px-2 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                  >
                                    3
                                  </span>
                                  <span
                                    onClick={() => {
                                      changeVal("6", "months");
                                    }}
                                    style={{ width: "50px", cursor: "pointer" }}
                                    className="flex justify-center rounded-full bg-gray-300 px-2 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                  >
                                    6
                                  </span>
                                  <span
                                    onClick={() => {
                                      changeVal("11", "months");
                                    }}
                                    style={{ width: "50px", cursor: "pointer" }}
                                    className="flex justify-center rounded-full bg-gray-300 px-2 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                  >
                                    11
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="mt-6">
                              <div className="text-xl font-bold leading-7 text-gray-900">
                                First Payment Date:
                              </div>
                              <div className="text-sm text-gray-500">
                              Please input date that your policy starts here.
                            </div>
                            <div className="mt-2 flex">
                                <input
                                  value={info.startDate}
                                  onChange={(e) => {
                                    changeVal(e.target.value, "startDate");
                                  }}
                                  type="date"
                                  name="start"
                                  id="start"
                                  min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                                  className="block w-6/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <div className="flex w-8/12 ml-3">
                                  <span
                                    onClick={() => {
                                      changeVal(
                                        moment().format().substring(0, 10),
                                        "startDate"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                    className="flex justify-center rounded-full bg-gray-300 px-3 py-1 text-sm font-medium text-gray-600 pt-2"
                                  >
                                    Today
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="mt-6">
                              <div className="text-xl font-bold leading-7 text-gray-900">
                                Preferred Payment Date:
                              </div>
                              <div className="text-sm text-gray-500">
                                What day of the month will regular payments be
                                made?
                              </div>
                              <div className="mt-2 flex">
                                <input
                                  value={info.paymentDate}
                                  onChange={(e) => {
                                    changeVal(e.target.value, "paymentDate");
                                  }}
                                  type="date"
                                  name="start"
                                  id="start"
                                  min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                                  className="block w-6/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                <div className="flex w-8/12 ml-3">
                                  <span
                                    onClick={() => {
                                      setDay(15);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2"
                                  >
                                    15
                                  </span>
                                  <span
                                    onClick={() => {
                                      setDay(25);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                  >
                                    25
                                  </span>
                                  <span
                                    onClick={() => {
                                      setDay(30);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                  >
                                    30
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="grid w-1/2">
                            <div>
                              <div className="text-2xl leading-7 text-gray-900">
                                Summary
                              </div>
                              <div className="grid pt-5">
                                <div className="text-lg leading-6 text-gray-900 w-100">
                                  Interest Rate:
                                </div>
                                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                  {summary.headers.interest || 0}%
                                </div>
                              </div>
                              <div className="grid pt-4">
                                <div className="text-lg leading-6 text-gray-900 w-100">
                                  Interest Amount:
                                </div>
                                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                  $
                                  {FormatNumber(
                                    nullTransform(
                                      parseFloat(
                                        summary.headers.totalInterest || 0
                                      ).toFixed(2)
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="grid pt-4">
                                <div className="text-lg leading-6 text-gray-900 w-100">
                                  Premium Financed + Interest:
                                </div>
                                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                  $
                                  {FormatNumber(
                                    nullTransform(
                                      parseFloat(
                                        summary.headers.total || 0
                                      ).toFixed(2)
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="grid pt-4 mb-4">
                                <div className="text-lg leading-6 text-gray-900 w-100">
                                  First Installment Amount:
                                </div>
                                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                  {summary.data.length > 0 && (
                                    <>
                                      $
                                      {FormatNumber(
                                        nullTransform(
                                          parseFloat(
                                            summary.data[0].instalment
                                          ).toFixed(2) || ""
                                        )
                                      )}
                                    </>
                                  )}
                                  {summary.data.length === 0 && <>$0</>}
                                </div>
                              </div>
                              <div className="grid pt-4 mb-4">
                                <div className="text-lg leading-6 text-gray-900 w-100">
                                  Monthly Installment Amount:
                                </div>
                                <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                  {summary.data.length > 0 && (
                                    <>
                                      $
                                      {FormatNumber(
                                        nullTransform(
                                          parseFloat(
                                            summary.data[1].instalment
                                          ).toFixed(2) || ""
                                        )
                                      )}{" "}
                                      starting{" "}
                                      {
                                        moment(
                                          nullTransform(
                                            summary.data[0].dueDate || ""
                                          )
                                        )
                                          .format("ll")
                                          .split(",")[0]
                                      }
                                    </>
                                  )}
                                  {summary.data.length === 0 && (
                                    <>$0 starting</>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="mt-7" />
                        <div className="flex w-100 pt-5">
                          <div className="grid w-100">
                            <div className="text-2xl leading-7 text-gray-900">
                              Payment Schedule
                            </div>
                            <div
                              style={{ minWidth: "750px" }}
                              className="mt-8 flow-root"
                            >
                              <div className="-mx-4 -my-2 overflow-x-auto">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                      <thead className="bg-gray-50">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                          >
                                            Payment Date
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Principal
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Interest
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Total Instalment
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          >
                                            Balance
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y divide-gray-200 bg-white">
                                        {summary.data.map((record, index) => (
                                          <tr key={index}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                              {moment(record.dueDate)
                                                .format()
                                                .substring(0, 10)}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                              $
                                              {FormatNumber(
                                                parseFloat(
                                                  record.principal
                                                ).toFixed(2)
                                              )}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                              $
                                              {FormatNumber(
                                                parseFloat(
                                                  record.interest
                                                ).toFixed(2)
                                              )}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                              $
                                              {FormatNumber(
                                                parseFloat(
                                                  record.instalment
                                                ).toFixed(2)
                                              )}
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                              $
                                              {FormatNumber(
                                                parseFloat(
                                                  record.balance
                                                ).toFixed(2)
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {action === "retail" && (
                    <div>
                      <div className="flex divide-x">
                        <div className="w-10/12 font-bold text-xl">
                          New {Capitalize(action)} Summary for {name}
                        </div>
                        <div className="w-2/12 mx-0">
                          {link !== undefined && (
                          <a
                            href={link}
                            type="button"
                            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            View Profile
                            <i className="fa-regular fa-user"></i>
                          </a>
                        )}
                          {link === undefined && (
                            <button
                              onClick={() =>
                                save(action, { info, policies, summary })
                              }
                              type="button"
                              className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Create Profile
                              <i className="fa-regular fa-user"></i>
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="flex w-100 pt-12">
                        <div className="grid w-1/2 mr-10">
                          <div>
                            <div className="text-xl font-bold leading-7 text-gray-900">
                              Insurance Premium:
                            </div>
                            <div className="text-sm text-gray-500">
                              Total premium to be financed,including tax.
                            </div>
                            <div className="mt-2">
                              <input
                                onChange={(e) => {
                                  changeVal(
                                    parseFloat(e.target.value),
                                    "premium"
                                  );
                                }}
                                type="number"
                                name="premium"
                                id="premium"
                                className="block w-9/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter premium"
                              />
                              <p>Premium Value: {premiumMask}</p>
                            </div>
                          </div>

                          <div className="mt-6">
                            <div className="text-xl font-bold leading-7 text-gray-900">
                              Contract Period:
                            </div>
                            <div className="text-sm text-gray-500">
                              Number of months for payback.
                            </div>
                            <div className="mt-2 flex">
                              <input
                                value={info.months}
                                onChange={(e) => {
                                  setMonth(e.target.value);
                                }}
                                type="number"
                                name="months"
                                id="months"
                                className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Enter months"
                              />
                              <div className="flex w-8/12 ml-3">
                                <span
                                  onClick={() => {
                                    changeVal("3", "months");
                                  }}
                                  style={{ width: "50px", cursor: "pointer" }}
                                  className="flex justify-center rounded-full bg-gray-300 px-2 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                >
                                  3
                                </span>
                                <span
                                  onClick={() => {
                                    changeVal("6", "months");
                                  }}
                                  style={{ width: "50px", cursor: "pointer" }}
                                  className="flex justify-center rounded-full bg-gray-300 px-2 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                >
                                  6
                                </span>
                                <span
                                  onClick={() => {
                                    changeVal("11", "months");
                                  }}
                                  style={{ width: "50px", cursor: "pointer" }}
                                  className="flex justify-center rounded-full bg-gray-300 px-2 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                >
                                  11
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="mt-6">
                            <div className="text-xl font-bold leading-7 text-gray-900">
                              First Payment Date:
                            </div>
  <div className="text-sm text-gray-500">
                              Please input date that your policy starts here.
                            </div>
                            <div className="mt-2 flex">
                              <input
                                value={info.startDate}
                                onChange={(e) => {
                                  changeVal(e.target.value, "startDate");
                                }}
                                type="date"
                                name="start"
                                id="start"
                                className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                              />
                              <div className="flex w-8/12 ml-3">
                                <span
                                  onClick={() => {
                                    changeVal(
                                      new Date().toISOString().split("T")[0], // Use today's date in the correct format
                                      "startDate"
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="flex justify-center rounded-full bg-gray-300 px-3 py-1 text-sm font-medium text-gray-600 pt-2"
                                >
                                  Today
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="mt-6">
                            <div className="text-xl font-bold leading-7 text-gray-900">
                              Preferred Payment Date:
                            </div>
                            <div className="text-sm text-gray-500">
                              What day of the month will regular payments be
                              made?
                            </div>
                            <div className="mt-2 flex">
                              <input
                                value={info.paymentDate}
                                onChange={(e) => {
                                  changeVal(e.target.value, "paymentDate");
                                }}
                                type="date"
                                name="start"
                                id="start"
                                min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                                className="block w-4/12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                              <div className="flex w-8/12 ml-3">
                                <span
                                  onClick={() => {
                                    setDay(15);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2"
                                >
                                  15
                                </span>
                                <span
                                  onClick={() => {
                                    setDay(25);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                >
                                  25
                                </span>
                                <span
                                  onClick={() => {
                                    setDay(30);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="flex justify-center rounded-full bg-gray-300 px-5 py-1 text-sm font-medium text-gray-600 pt-2 ml-2"
                                >
                                  30
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid w-1/2">
                          <div>
                            <div className="text-2xl leading-7 text-gray-900">
                              Summary
                            </div>
                            <div className="grid pt-5">
                              <div className="text-lg leading-6 text-gray-900 w-100">
                                Interest Rate:
                              </div>
                              <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                {summary.headers.interest || 0}%
                              </div>
                            </div>
                            <div className="grid pt-4">
                              <div className="text-lg leading-6 text-gray-900 w-100">
                                Interest Amount:
                              </div>
                              <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                $
                                {FormatNumber(
                                  nullTransform(
                                    parseFloat(
                                      summary.headers.totalInterest || 0
                                    ).toFixed(2)
                                  )
                                )}
                              </div>
                            </div>
                            <div className="grid pt-4">
                              <div className="text-lg leading-6 text-gray-900 w-100">
                                Premium Financed + Interest:
                              </div>
                              <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                $
                                {FormatNumber(
                                  nullTransform(
                                    parseFloat(
                                      summary.headers.total || 0
                                    ).toFixed(2)
                                  )
                                )}
                              </div>
                            </div>
                            <div className="grid pt-4 mb-4">
                              <div className="text-lg leading-6 text-gray-900 w-100">
                                First Installment Amount:
                              </div>
                              <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                {summary.data.length > 0 && (
                                  <>
                                    $
                                    {FormatNumber(
                                      nullTransform(
                                        parseFloat(
                                          summary.data[0].instalment
                                        ).toFixed(2) || ""
                                      )
                                    )}
                                  </>
                                )}
                                {summary.data.length === 0 && <>$0</>}
                              </div>
                            </div>
                            <div className="grid pt-4 mb-4">
                              <div className="text-lg leading-6 text-gray-900 w-100">
                                Monthly Installment Amount:
                              </div>
                              <div className="text-sm font-bold leading-6 text-gray-900 w-100">
                                {summary.data.length > 0 && (
                                  <>
                                    $
                                    {FormatNumber(
                                      nullTransform(
                                        parseFloat(
                                          summary.data[1].instalment
                                        ).toFixed(2) || ""
                                      )
                                    )}{" "}
                                    starting{" "}
                                    {
                                      moment(
                                        nullTransform(
                                          summary.data[0].dueDate || ""
                                        )
                                      )
                                        .format("ll")
                                        .split(",")[0]
                                    }
                                  </>
                                )}
                                {summary.data.length === 0 && <>$0 starting</>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-7" />
                      <div className="flex w-100 pt-5">
                        <div className="grid w-100">
                          <div className="text-2xl leading-7 text-gray-900">
                            Payment Schedule
                          </div>
                          <div
                            style={{ minWidth: "750px" }}
                            className="mt-8 flow-root"
                          >
                            <div className="-mx-4 -my-2 overflow-x-auto">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                          Payment Date
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                          Principal
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                          Interest
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                          Total Instalment
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                          Balance
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                      {summary.data.map((record, index) => (
                                        <tr key={index}>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {record.dueDate}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            $
                                            {FormatNumber(
                                              parseFloat(
                                                record.principal
                                              ).toFixed(2)
                                            )}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            $
                                            {FormatNumber(
                                              parseFloat(
                                                record.interest
                                              ).toFixed(2)
                                            )}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            $
                                            {FormatNumber(
                                              parseFloat(
                                                record.instalment
                                              ).toFixed(2)
                                            )}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            $
                                            {FormatNumber(
                                              parseFloat(
                                                record.balance
                                              ).toFixed(2)
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <AccessControl
                      permission={"createAmortizationSample"}
                      fallback={<></>}
                      target={["god"]}
                    >
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => {
                          save(action, { info, policies, summary });
                        }}
                      >
                        Continue
                      </button>
                    </AccessControl>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
